<template>
	<Table
		:pgLimitGet="pgLimit"
		backendDirectory="cliente/listar"
    backendDirectoryUpdate="cliente/atualizar/situacao"
		:busca="{
            busca: busca.busca || null
        }"
    :parametro="{
      busca: busca.busca || null
    }"
		:filter="true"
		:keydown="keydown"
		:headers="{
            idcliente: {nome: 'ID', tipo: permissao('cliente_get') ? 'link' : 'texto', link: '/cliente/get/', class: 'width'},
            nome: {nome: 'Nome', tipo: 'texto'},
            endereco: {nome: 'Endereço', tipo: 'objeto', campos: ['endereco','numero','cidade'], divisor: ', '},
            situacao: {nome: 'Medalha da Cobrança', tipo:'select', permissao: permissao('atualiza_situacao_cliente'), situacoes: [{idsituacao: 0, situacao: 'Sem Medalha'},{idsituacao: 1, situacao: 'Ouro'},{idsituacao: 2, situacao: 'Prata'},{idsituacao: 3, situacao: 'Bronze'}] },
        }"
	>
		<v-col>
			<v-text-field
				v-model="busca.busca"
				label="Buscar"
				outlined
				dense
				@keydown.enter="keydown = !keydown"
			/>
		</v-col>
	</Table>
</template>

<script>
import { mapState } from "vuex";
import Table from "../Widgets/Table";

export default {
	name: "ClienteLista",
	components: { Table },
	data: () => ({
		busca: {},
		keydown: false,
	}),
	computed: {
		...mapState(["pgLimit"]),
	},
};
</script>

<style scoped>
</style>